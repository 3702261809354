@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #0f172a;
  font-family: 'Noto Sans', sans-serif;
}
body {
  background: linear-gradient(45deg, #e2e8f0 0%, #e7e5e4 100%);
  background-attachment: fixed;
  height: calc(100vh);
}
body h1 {
  font-size: 2.2em;
  text-align: center;
  margin: 0.5em 0 0.25em;
}
body h2 {
  font-size: 1.5em;
  margin: 0.5em 0;
}
body h3 {
  font-size: 1.2em;
  margin: 0.5em 0;
}
#root {
  width: 100%;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrap {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.page__wrapper {
  display: flex;
  justify-content: center;
  margin: 1em 4em;
  flex-wrap: wrap;
}
.page {
  border-radius: 50%;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: #0d9488;
  margin: 0.5em;
}
.page:hover {
  background-color: #14b8a6;
}
.page__current {
  background-color: #2dd4bf;
}
.pswrd_eye {
  background-color: transparent;
  outline: none;
  border: none;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: left center;
  width: 24px;
  height: 24px;
}
.eye_show {
  background-image: url('../assets/img/pswrd_show.svg');
}
.eye_hide {
  background-image: url('../assets/img/pswrd_hide.svg');
}
.preloader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #AAA;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}
@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}
